<template>

    <div style="background-color: var(--white);">
        <br />
        <div style="color: var(--darkBackground); padding: 5px">
            <h4>ANTI MONEY LAUNDERING POLICY</h4>
            <ul>
                <li>
                    We keep anti-money laundering measures and practices in order to prevent such actions being
                    performed through the Website and as a whole where applicable.
                </li>
                <li>
                    By using the Website, you represent and warrant that:
                </li>
            </ul>
            <p>
                a) You are a rightful owner of the money that you deposit, or have deposited to your Account on the
                Website.
            </p>
            <p>
                b) Above mentioned funds were obtained in a legal way and are not the proceeds of any crime.
            </p>
            <p>
                c) You will not in any way use the Website for illegal activity.
            </p>
            <p>
                d) Information you provide to the Company is correct and up to date.
            </p>
            <p>
                e) You will inform us if you are or become a Politically Exposed Person or an important political party
                official
            </p>
            <ul>
                <li>
                    You will be asked to provide documents that will verify the information you provided to us,
                    origination of the funds that you deposited. Company may ask such documents anytime.
                </li>
                <li>
                    If any of the information that you provide to us is untrue, inaccurate, misleading or otherwise
                    incomplete, you will be in breach of contract and we reserve the right to terminate your account
                    immediately and/or prevent you from using the services, in addition to any other action that we may
                    choose to take.
                </li>
                <li>
                    When making the decision about requesting the documents Company uses risk-based approach. This means
                    that there is a constant monitoring of your activity during the usage of the Website, if any of your
                    actions are found to be suspicious, for example, exceeding the amount allowed for deposits or
                    withdrawal, or drastic changes in your behavior you may be subject for additional verification.
                </li>
                <li>
                    Any such suspicious activity may be reported to the competent authorities.
                </li>
                <li>
                    Company may perform repeatable checks from time to time.
                </li>
                <li>
                    If for any reason you will not be able to pass the verification, your account will be frozen, until
                    the verification is performed. During this term you will not be able to deposit or withdraw the
                    funds from your Account.
                </li>
                <li>
                    All the information received from you in the process of identification is processed in accordance
                    with applicable legislation.
                </li>
                <li>
                    The records about the measures performed during the verification and information obtained during
                    such verification shall be kept for not less than 10 years. After the expiry of such term the
                    information will be anonymized.
                </li>
            </ul>
        </div>


        <bottom-navigation active-bets="0" page="login"></bottom-navigation>

    </div>

</template>

<script>

import BottomNavigation from "@/components/BottomNavigation";

export default {
    name: 'privacy',
    components: {

        BottomNavigation
    },
    mounted: function () {

        this.$store.dispatch("setCurrentPage", "responsible");
        this.reloadProfile();

    }

}
</script>